import React, { useState, useEffect, useRef, useReducer } from "react";
import {
  Grid,
  Typography,
  LinearProgress,
  CircularProgress,
  Backdrop,
  Button,
  useTheme,
} from "@material-ui/core";
import { areAnyTestsSelected } from "../common/CreateTestsCommonUtils";
import { ExtendedTest } from "../common/CreateTests.interfaces";
import { useStyles } from "./ChooseTestFormStyles";
import { CollapsableTestsList } from "../components/CollapsableTestsList";
import { ChooseTestsSelectionList } from "../components/ChooseTestsSelectionList";
import {
  getCategories,
  isAnySelectedTestInvalid,
  getUpdateHealthScoreTestsMutationVariables,
  getCreateHealthScoreTestsMutationVariables,
} from "./ChooseHealthScoreTestsFormUtils";
import { SearchField } from "../../../../../_common/components/SearchField/SearchField";
import { ChooseTestsFilter } from "../components/filter/ChooseTestFilter";
import { chunk } from "lodash";
import {
  PopoverDialog,
  PopoverDialogButton,
  PopoverDialogDefaultIdentifiers,
} from "../../../../../_common/components/PopoverDialog/PopoverDialog";
import { MAX_TESTS_SELECTION_LIMIT } from "./CreateTestsCommonConst";
import {
  chooseTestsReducer,
  initialChooseTestsFormValue,
} from "./chooseTestsReducer";
import { ChooseTestsDispatch } from "../../../../../_common/contexts/Permissions/ChooseTestsDispatch/ChooseTestsDispatch";
import { AutomaticThresholdSettings } from "./AutomaticThresholdSettings";
import InfoRoundedIcon from "@material-ui/icons/InfoRounded";
import {
  isResolutionWithin,
  ResolutionStep,
} from "../../../../../_common/utils/window/window";
import { StickyWrapper } from "../../StickyWrapper/StickyWrapper";
import { useSaveChangesContext } from "../../../SaveChangesProvider";
import { getTotalShowingTestsCount } from "../components/ChooseTestsFormCountersUtils";
import { Alert } from "@lumar/shared";
import {
  GetTestSuiteQuery,
  useCreateHealthScoreTestsMutation,
  useDeleteHealthScoreTestsMutation,
  useGetTestSuiteHealthScoreTestsQuery,
  useUpdateAutomaticThresholdMutation,
  useUpdateHealthScoreTestsMutation,
} from "../../../../../graphql";
import { useFilteredReportCategories } from "../utils/useFilteredReportCategories";

export interface ChooseHealthScoreTestsFormProps {
  absoluteThresholdUpLimit: number;
  testSuite: NonNullable<GetTestSuiteQuery["node"]>;
  project: NonNullable<GetTestSuiteQuery["getProject"]>;
  onSuccess: () => void;
  useTestSuiteTestsQueryObject: ReturnType<
    typeof useGetTestSuiteHealthScoreTestsQuery
  >;
  hasChildren?: boolean;
  smartThresholdSettings: {
    isEnabled: boolean;
    enabledForAllTests: boolean;
  };
  setSmartThresholdSettings: React.Dispatch<
    React.SetStateAction<{
      isEnabled: boolean;
      enabledForAllTests: boolean;
    }>
  >;
}

const popoverButtons: PopoverDialogButton[] = [
  {
    label: "Ok",
    identifier: PopoverDialogDefaultIdentifiers.OK,
    color: "secondary",
  },
];

// eslint-disable-next-line max-lines-per-function, max-statements, complexity
export function ChooseHealthScoreTestsForm(
  props: ChooseHealthScoreTestsFormProps,
): JSX.Element {
  const [chooseTestsFormState, chooseTestsFormDispatch] = useReducer(
    chooseTestsReducer,
    initialChooseTestsFormValue,
  );

  const popupReference = useRef(null);
  const classes = useStyles();
  const theme = useTheme();

  const {
    refetch: refetchTestsAndCustomExtractions,
    data: tests,
    error: testsError,
    loading: testsLoading,
  } = props.useTestSuiteTestsQueryObject;

  const [submitError, setSubmitError] = useState<string | undefined>();
  const [submitting, setSubmitting] = useState<boolean>();
  const [shouldRefreshCategories, setShouldRefreshCategories] =
    useState<boolean>(true);
  const MAX_ALLOWED_GRAPH_QL_CHUNK_SIZE = 99;
  const anyTestsSelected = areAnyTestsSelected(chooseTestsFormState.categories);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const [createTestsCall] = useCreateHealthScoreTestsMutation();
  const [updateTestsCall] = useUpdateHealthScoreTestsMutation();
  const [deleteTestsCall] = useDeleteHealthScoreTestsMutation();
  const [updateAutomaticThreshold] = useUpdateAutomaticThresholdMutation();

  const saveChangesContext = useSaveChangesContext();

  // Original code worked this way.
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
  const testsNode = tests?.node!;

  const {
    data: filteredCategories,
    loading: loadingCategories,
    error: categoriesError,
  } = useFilteredReportCategories(props.testSuite.moduleCode);

  // eslint-disable-next-line complexity
  useEffect(() => {
    function categoriesLoadedOrTestSuiteChangedOrSetToReload() {
      return (
        chooseTestsFormState.categories.length === 0 || shouldRefreshCategories
      );
    }

    if (
      !loadingCategories &&
      !testsLoading &&
      categoriesLoadedOrTestSuiteChangedOrSetToReload() &&
      tests &&
      filteredCategories
    ) {
      setShouldRefreshCategories(false);

      const newCategories = getCategories([...filteredCategories], testsNode);
      chooseTestsFormDispatch({
        type: "SET_CATEGORIES",
        payload: { categories: newCategories },
      });
    }
  }, [
    chooseTestsFormState.categories.length,
    shouldRefreshCategories,
    tests,
    testsLoading,
    testsNode,
    props.testSuite.moduleCode,
    loadingCategories,
    filteredCategories,
  ]);

  useEffect(() => {
    setShouldRefreshCategories(true);
  }, [tests]);

  function createTestsFn(tests: ExtendedTest[]) {
    const testList = tests.filter(
      (test) => !test.data.id && test.extended.selected,
    );
    if (testList.length) {
      const variables = getCreateHealthScoreTestsMutationVariables(
        testList,
        props.testSuite.id,
      );
      return createTestsCall({
        variables,
      });
    }
  }

  function updateTestsFn(tests: ExtendedTest[]) {
    const testList = tests.filter(
      (test) => test.data.id && test.extended.selected,
    );
    if (testList.length) {
      const variables = getUpdateHealthScoreTestsMutationVariables(testList);
      return updateTestsCall({
        variables,
      });
    }
  }

  function deleteTestsFn(tests: ExtendedTest[]) {
    const testList = tests.filter(
      (test) => test.data.id && !test.extended.selected,
    );
    if (testList.length) {
      return deleteTestsCall({
        variables: {
          testIds: testList.map((e) => e.data.id),
        },
      });
    }
  }

  async function updateAutomaticThresholdOnTestSuite() {
    try {
      setSubmitting(true);
      await updateAutomaticThreshold({
        variables: {
          testSuiteId: props.testSuite.id,
          automaticThreshold: props.smartThresholdSettings.isEnabled,
          automaticThresholdEnabledForAll:
            props.smartThresholdSettings.enabledForAllTests,
        },
        refetchQueries: ["getTestSuite"],
      });
      setSubmitting(false);
    } catch {
      setSubmitting(false);
      setSubmitError(
        "There has been an error while saving data. Please refresh the page and try again.",
      );
    }
  }

  async function resolveTestsChunks(tests: ExtendedTest[][]): Promise<void> {
    try {
      const flatList = tests.reduce<ExtendedTest[]>((r, t) => [...r, ...t], []);
      await createTestsFn(flatList);
      await updateTestsFn(flatList);
      await deleteTestsFn(flatList);
      await updateAutomaticThresholdOnTestSuite();
      setSubmitError(undefined);
      refetchTestsAndCustomExtractions();
      setSubmitting(false);
    } catch (e) {
      refetchTestsAndCustomExtractions();
      setSubmitting(false);
      throw e;
    }
  }

  async function handleSave() {
    if (testsLoading) return;
    if (!anyTestsSelected) {
      setSubmitError("Please add tests in order to continue.");
      setSubmitting(false);
      throw new Error("Please add tests in order to continue.");
    }
    const testChunks = chunk(
      chooseTestsFormState.categories.flatMap((category) => category.tests),
      MAX_ALLOWED_GRAPH_QL_CHUNK_SIZE,
    );

    if (testChunks.length) {
      setSubmitting(true);
      return resolveTestsChunks(testChunks);
    }
  }

  async function handleSubmit() {
    if (!areAnyTestsSelected(chooseTestsFormState.categories)) {
      return;
    }

    if (
      isAnySelectedTestInvalid(
        chooseTestsFormState.categories,
        props.absoluteThresholdUpLimit,
      )
    ) {
      setSubmitError(
        "Please fill in all required fields in order to continue.",
      );
      return;
    }

    try {
      await handleSave();
      props.onSuccess();
    } catch {
      setSubmitError(
        "There has been an error while saving data. Please refresh the page and try again.",
      );
    }
  }

  useEffect(() => {
    if (!props.testSuite.parent) {
      saveChangesContext.registerCallback(handleSave, "step3");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chooseTestsFormState]);

  function clearSubmitError() {
    setSubmitError(undefined);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function openMaxTestSelectionPopup() {
    setAnchorEl(popupReference.current);
  }

  const filtersContainer = useRef<HTMLDivElement>(null);
  const testsContainer = useRef<HTMLDivElement>(null);
  const [computedMaxHeight, setComputedMaxHeight] = useState<number>(640);

  function calcHeight() {
    const areLocatedNextToEachOther = isResolutionWithin(ResolutionStep.SM);
    if (areLocatedNextToEachOther) {
      const filtersContainerBoundingBox =
        filtersContainer.current?.getBoundingClientRect();
      const testsConteinerBoundingBox =
        testsContainer.current?.getBoundingClientRect();
      if (testsConteinerBoundingBox && filtersContainerBoundingBox) {
        const max = Math.max(
          testsConteinerBoundingBox?.height -
            filtersContainerBoundingBox?.height,
          640,
        );
        setComputedMaxHeight(max);
      }
    } else {
      setComputedMaxHeight(640);
    }
  }
  useEffect(() => {
    window.addEventListener("resize", calcHeight);
    setTimeout(() => {
      calcHeight();
    }, 1000);

    return () => window.removeEventListener("resize", calcHeight);
  }, [chooseTestsFormState.categories]);

  if (loadingCategories || testsLoading) {
    return <CircularProgress data-testid="choose-tests-form-loading" />;
  }

  /* eslint-disable no-nested-ternary */
  return (
    <ChooseTestsDispatch.Provider value={chooseTestsFormDispatch}>
      <Grid container ref={popupReference}>
        <PopoverDialog
          anchorElement={anchorEl}
          handleAction={handleClose}
          open={Boolean(anchorEl)}
          text={`The maximum number of tests that you can select is ${MAX_TESTS_SELECTION_LIMIT}. Please deselect existing tests before choosing more.`}
          title="Can’t select more tests"
          buttons={popoverButtons}
          id={`{simple-popover-warning-${MAX_TESTS_SELECTION_LIMIT}}`}
          centered={true}
          icon={<InfoRoundedIcon className={classes.icon} />}
        />
        {categoriesError || testsError ? (
          <Alert severity="warning">
            There has been an error while loading data for this view.
          </Alert>
        ) : !loadingCategories && chooseTestsFormState.categories.length > 0 ? (
          <>
            <Grid item xs={12}>
              <div className={classes.padding}>
                <Grid container className={classes.containerStart} spacing={3}>
                  <Grid item sm={12} md={4}>
                    <div className={classes.filterAndControlsWrapper}>
                      <div ref={filtersContainer} id="layoutAffector">
                        <div
                          data-testid="tests-available"
                          style={{ marginBottom: theme.spacing(1) }}
                        >
                          <Typography
                            color="textPrimary"
                            data-cy="available-tests-count"
                            className={classes.totalTestsTitle}
                          >
                            Tests available (
                            {getTotalShowingTestsCount(
                              chooseTestsFormState.filter,
                              chooseTestsFormState.categories,
                            )}
                            )
                          </Typography>
                          <SearchField />
                        </div>
                        <ChooseTestsFilter
                          chooseTestsFormState={chooseTestsFormState}
                          filterToggle={() => {
                            setTimeout(() => {
                              calcHeight();
                            }, 1000);
                          }}
                        />
                      </div>
                      <ChooseTestsSelectionList
                        maxHeight={computedMaxHeight}
                        chooseTestsFormState={chooseTestsFormState}
                        clearSubmitError={clearSubmitError}
                        openMaxTestSelectionPopup={openMaxTestSelectionPopup}
                        renderingProgressed={calcHeight}
                      />
                    </div>
                  </Grid>
                  <Grid item sm={12} md={8} ref={testsContainer}>
                    {!props.hasChildren && !props.testSuite.parent && (
                      <AutomaticThresholdSettings
                        smartThresholdSettings={props.smartThresholdSettings}
                        handleSmartThresholdSettingsChange={
                          props.setSmartThresholdSettings
                        }
                      />
                    )}
                    <Typography
                      variant="h5"
                      className={classes.collapsableListHeadingOtherTests}
                    >
                      Tests selected
                    </Typography>
                    <CollapsableTestsList
                      forceNumericThreshold={true}
                      absoluteThresholdUpLimit={props.absoluteThresholdUpLimit}
                      isJiraIntegrationConnected={Boolean(
                        props.testSuite.testSuiteJiraIntegration,
                      )}
                      categories={chooseTestsFormState.categories}
                      clearSubmitError={clearSubmitError}
                      renderingProgressed={() => calcHeight()}
                      smartThresholdSettings={props.smartThresholdSettings}
                    />

                    {submitError && (
                      <Alert
                        data-cy="submit-error"
                        className={classes.submitError}
                        severity="error"
                      >
                        {submitError}
                      </Alert>
                    )}
                  </Grid>
                </Grid>
              </div>
              <Backdrop open={!!submitting} className={classes.backdrop}>
                <CircularProgress
                  data-testid="choose-tests-form-loading-indicator"
                  color="inherit"
                />
              </Backdrop>

              <StickyWrapper>
                {anyTestsSelected ? (
                  <Button
                    disabled={!anyTestsSelected || submitting}
                    onClick={handleSubmit}
                    data-testid="choose-tests-form-save"
                    data-cy="choose-tests-form-save"
                    data-pendo="auto-testsuite-edit-add-choose-tests-save-step"
                    variant="contained"
                    color="primary"
                  >
                    Save
                  </Button>
                ) : (
                  <Button
                    disabled={submitting}
                    onClick={props.onSuccess}
                    data-testid="choose-tests-form-save"
                    data-cy="choose-tests-form-save"
                    data-pendo="auto-testsuite-edit-add-choose-tests-save-step"
                    variant="contained"
                    color="primary"
                  >
                    Next step
                  </Button>
                )}
              </StickyWrapper>
            </Grid>
          </>
        ) : (
          <div className={classes.progresssBar}>
            <LinearProgress key="linear-progress" />
          </div>
        )}
      </Grid>
    </ChooseTestsDispatch.Provider>
  );
  /* eslint-enable no-nested-ternary */
}
