import React from "react";
import { BuildStatusIcon } from "../../../_common/components/BuildStatusIcon/BuildStatusIcon";
import {
  BuildTestStatus,
  ExtendedBuildStatus,
} from "../../../_common/interfaces/BuildTestStatus";
import {
  Paper,
  Grid,
  makeStyles,
  Typography,
  Box,
  Tooltip,
} from "@material-ui/core";
import { useAccountRouteMatch } from "../../../_common/hooks/useAccountRouteMatch/useAccountRouteMatch";
import { getMatchingBorderColorForStatus } from "../../../_common/components/BuildStatusIcon/BuildStatusIconUtils";
import { isReportReady } from "./BuildTestListItemUtils";
import { Link } from "react-router-dom";
import { Routes } from "../../../_common/routes/routes";
import { StatusIconSize } from "../../../_common/components/StatusIcon/StatusIcon";
import { TestsToDisplayOption } from "../../TestSuiteReport/components/TestSuiteReportListUtils";
import { SchedulerTextOptions } from "../factories/buildTestItemInfoFactory";
import { TestCountChips } from "../../TestSuiteList/test-suite-list/TestCountChips";
import { createLastRunText } from "../../../_common/utils/createLastRunText/createLastRunText";
import { BuildStatus } from "../../../graphql";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { CalendarOutlined, ClockOutlined, Chip } from "@lumar/shared";

export interface BuildTestListItemProps {
  id: string;
  buildId: string;
  testSuiteId: string;
  status: BuildTestStatus;
  lastRun: string;
  duration: string;
  failedTests: number | string;
  warningTests: number | string;
  passedTests: number | string;
  failedHealthScoreTests: number | string;
  warningHealthScoreTests: number | string;
  passedHealthScoreTests: number | string;
  triggerType: SchedulerTextOptions;
  updatedAt: string;
}

const useStyles = makeStyles((theme) => ({
  root: (props: BuildTestListItemProps) => {
    return {
      background: isReportReady(props.status) ? "white" : "#F7F8F8",
      display: "flex",
      alignItems: "center",
      marginBottom: 12,
      padding: theme.spacing(2, 1, 2, 3),
      lineHeight: theme.typography.pxToRem(14),
      borderRadius: "8px",
      border: `1px solid ${theme.palette.grey[300]}`,
      borderLeft: `4px solid ${getMatchingBorderColorForStatus(
        props.status,
        theme,
      )}`,
      boxShadow:
        "0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)",
      "&:hover": isReportReady(props.status)
        ? {
            boxShadow: theme.shadows[2],
            cursor: "pointer",
          }
        : undefined,
      [theme.breakpoints.down("md")]: {
        alignItems: "start",
        padding: theme.spacing(2, 1, 2, 2),
      },
      [theme.breakpoints.down("xs")]: {
        padding: theme.spacing(1),
      },
    };
  },
  chipsWrapper: {
    display: "flex",
    justifyContent: "flex-start",
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(2),
    },
  },
  durationText: {
    fontSize: theme.typography.pxToRem(14),
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    color: theme.palette.grey[600],
    fontWeight: 400,
    paddingLeft: theme.spacing(1),
  },
  durationIcon: {
    width: "20px",
    color: theme.palette.grey[400],
  },
  iconWrapper: {
    minWidth: 70,
    paddingRight: theme.spacing(2),
  },
  endIconsWrapper: {
    minWidth: 150,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    [theme.breakpoints.down("md")]: {
      minWidth: 100,
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
      flexDirection: "column",
    },
  },
  hidableButton: {
    alignItems: "center",
    "& button": {
      opacity: 0,
      transition: "opacity 0.1s ease-in-out",
    },
    "& p": {
      opacity: 1,
      transition: "opacity 0.1s ease-in-out",
    },
    "&:hover button": {
      opacity: 1,
    },
    "&:hover p": {
      opacity: 0,
    },
  },
  floatingBox: {
    position: "absolute",
  },
  buttonCopy: {
    height: "28px",
  },
  icon: {
    paddingRight: theme.spacing(2),
    color: theme.palette.grey[700],
    width: "25px",
  },
}));

const testIdPrefix = "build-test-list-item";

export function BuildTestListItem(props: BuildTestListItemProps) {
  const classes = useStyles(props);

  return (
    <ConditionalLink
      id={props.id}
      testSuiteId={props.testSuiteId}
      status={props.status}
    >
      <Paper
        className={classes.root}
        elevation={0}
        data-cyalso={`${testIdPrefix}-${props.status.toLocaleLowerCase()}`}
        data-cy={testIdPrefix}
        data-testid={testIdPrefix}
        data-pendo={`${testIdPrefix}-${props.status.toLocaleLowerCase()}`}
      >
        <Box className={classes.iconWrapper}>
          <BuildStatusIcon
            status={props.status}
            size={StatusIconSize.LG}
            inline
          />
        </Box>
        <Grid container alignItems="center" direction="row">
          <Tooltip title="Last run date" placement="bottom-start">
            <Grid
              item
              container
              lg={props.status !== BuildStatus.Running ? 3 : 5}
              md={12}
              xs={12}
              className={classes.chipsWrapper}
            >
              {props.status !== BuildStatus.Running && (
                <Box display="flex" alignItems="center">
                  <CalendarOutlined className={classes.durationIcon} />
                  <Typography
                    data-testid="last-run-timestamp"
                    data-cy="last-run-timestamp"
                    className={classes.durationText}
                  >
                    {createLastRunText(
                      props.status,
                      props.updatedAt,
                      props.lastRun,
                    )}
                  </Typography>
                </Box>
              )}
            </Grid>
          </Tooltip>
          <Grid
            item
            lg={props.status !== BuildStatus.Running ? 3 : 1}
            md={12}
            xs={12}
          >
            {Object.values(ExtendedBuildStatus).includes(
              props.status as ExtendedBuildStatus,
            ) && (
              <Tooltip title="Duration" placement="bottom-start">
                <Box display="flex" alignItems="center">
                  <ClockOutlined className={classes.durationIcon} />
                  <Typography
                    data-testid="last-run-duration"
                    data-cy="last-run-duration"
                    className={classes.durationText}
                  >
                    {props.duration}
                  </Typography>
                </Box>
              </Tooltip>
            )}
          </Grid>
          <Grid
            item
            lg={4}
            md={8}
            sm={10}
            xs={12}
            className={classes.chipsWrapper}
          >
            <TestCountChips
              buildId={props.id}
              testSuiteId={props.testSuiteId}
              passedTests={props.passedTests}
              warningTests={props.warningTests}
              failedTests={props.failedTests}
              failedHealthScoreTests={props.failedHealthScoreTests}
              warningHealthScoreTests={props.warningHealthScoreTests}
              passedHealthScoreTests={props.passedHealthScoreTests}
              status={props.status}
              pendoIdSuffix="build-list"
            />
          </Grid>
          <Grid item lg={2} md={12} xs={12} className={classes.chipsWrapper}>
            <Chip
              color={
                props.triggerType === SchedulerTextOptions.API
                  ? "blue"
                  : props.triggerType === SchedulerTextOptions.MANUAL
                    ? "purple"
                    : "cyan"
              }
              label={props.triggerType}
              data-testid={`dc-chip-${
                props.triggerType
                  ? props.triggerType
                      .trim()
                      .toLocaleLowerCase()
                      .replace(/\s/g, "")
                  : ""
              }`}
              data-cy="dc-chip"
            />
          </Grid>
        </Grid>
        <Box className={classes.endIconsWrapper}>
          {isReportReady(props.status) && (
            <ChevronRightIcon
              data-testid="step-into-icon"
              fontSize="large"
              className={classes.icon}
            />
          )}
        </Box>
      </Paper>
    </ConditionalLink>
  );
}

interface ConditionalLinkProps
  extends Pick<BuildTestListItemProps, "status" | "id" | "testSuiteId"> {
  children: React.ReactNode;
}

function ConditionalLink(props: ConditionalLinkProps) {
  const accountId = useAccountRouteMatch();
  const isBuildFinished = isReportReady(props.status);

  const url = Routes.TestResults.getUrl({
    accountId,
    testSuiteId: props.testSuiteId,
    buildTestId: props.id,
    selectedType: TestsToDisplayOption.ALL,
  });

  if (isBuildFinished) {
    return (
      <Link style={{ textDecoration: "none" }} to={url}>
        {props.children}
      </Link>
    );
  }

  return <>{props.children}</>;
}
