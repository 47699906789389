import {
  Box,
  Collapse,
  Divider,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { InfoTooltip } from "../../../../../_common/components/InfoTooltip/InfoTooltip";
import { SwitchWithIcon } from "@lumar/shared";

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: theme.spacing(3.375),
    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(5, 0, 3, 0),
    },
  },
  title: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(15),
    lineHeight: theme.typography.pxToRem(18),
    marginRight: theme.spacing(1),
    color: theme.palette.grey[800],
    padding: "19px,0",
  },
  subtitle: {
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(17),
    color: theme.palette.grey[700],
    marginRight: theme.spacing(1),
    padding: "8.5px,0",
  },
  subtitleWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "5px 0px",
  },
  icon: {
    color: theme.palette.navy[200],
  },
  divider: {
    color: theme.palette.navy[50],
  },
  formControlLabel: {
    fontSize: 14,
    color: theme.palette.navy[500],
    paddingRight: "8px",
  },
  radioGroup: {
    margin: theme.spacing(2, 0),
  },
  smallIcon: {
    color: "#BDC2C5",
    fontSize: 18,
    marginLeft: 8,
    paddingTop: 2,
  },
}));

export interface AutomaticThresholdSettingsProps {
  smartThresholdSettings: {
    isEnabled: boolean;
    enabledForAllTests: boolean;
  };
  handleSmartThresholdSettingsChange: React.Dispatch<
    React.SetStateAction<{
      isEnabled: boolean;
      enabledForAllTests: boolean;
    }>
  >;
}

export function AutomaticThresholdSettings({
  smartThresholdSettings,
  handleSmartThresholdSettingsChange,
}: AutomaticThresholdSettingsProps) {
  const classes = useStyles();
  const { isEnabled, enabledForAllTests } = smartThresholdSettings;

  return (
    <Box
      className={classes.container}
      data-testid="automatic-threshold-wrapper"
      data-cy="automatic-threshold"
    >
      <Box display="flex" alignItems="center" mb={2}>
        <Typography
          color="textPrimary"
          data-testid="automatic-threshold-title"
          className={classes.title}
        >
          Test settings
        </Typography>
        <InfoTooltip
          data-pendo="auto-tooltip-automatic-threshold-settings"
          data-testid="test-settings-tooltip"
          title={"These settings will apply to all tests in this test suite."}
        />
      </Box>
      <Divider className={classes.divider} />
      <Box className={classes.subtitleWrapper}>
        <Box display="flex" alignItems="center">
          <Typography
            color="textPrimary"
            data-testid="automatic-threshold-subtitle"
            className={classes.subtitle}
          >
            Use smart thresholds
          </Typography>
          <InfoTooltip
            data-pendo="auto-tooltip-automatic-threshold-info"
            data-testid="automatic-threshold-tooltip"
            title={
              <>
                <b>Smart thresholds</b>
                <p>
                  Learn from previous test results and update test thresholds
                  automatically. The more tests you run and the better your
                  results, the more strict your thresholds will become and the
                  better your SEO outcome will be. The test settings will be set
                  to an optimal value based on previous test suite runs.
                </p>
                <ul style={{ paddingInlineStart: 15 }}>
                  <li>
                    If a test passes with a value better than the test
                    threshold, the threshold will automatically update to the
                    appropriate new value.
                  </li>
                  <li>
                    When enabled, the test thresholds will be optimized for all
                    tests in this test suite. If you want enable this for
                    specific tests only, enable the &quot;Set on a per test
                    basis&quot; toggle.
                  </li>
                </ul>
              </>
            }
          />
        </Box>
        <SwitchWithIcon
          checked={isEnabled}
          onChange={(event) => {
            handleSmartThresholdSettingsChange((currentSettings) => ({
              ...currentSettings,
              isEnabled: event.target.checked,
            }));
          }}
          data-testid="automatic-threshold-toggle"
          data-cy="automatic-threshold-toggle"
          data-pendo={`auto-enable-smart-threshold-toggle-${
            isEnabled ? "on" : "off"
          }`}
          inputProps={{ "aria-label": "Enable smart threshold" }}
        />
      </Box>
      <Divider className={classes.divider} />
      <Collapse in={isEnabled}>
        <Box>
          <RadioGroup
            aria-label="smart-threshold-settings"
            name="smart-threshold-settings"
            value={enabledForAllTests ? "all" : "individual"}
            onChange={(event) => {
              handleSmartThresholdSettingsChange((currentSettings) => ({
                ...currentSettings,
                enabledForAllTests: event.target.value === "all",
              }));
            }}
            classes={{ root: classes.radioGroup }}
          >
            <FormControlLabel
              data-cy="automatic-threshold-check-all"
              value="all"
              name="Apply to all tests"
              control={<Radio />}
              label="Apply to all tests"
              data-pendo="auto-smart-threshold-apply-to-all-tests"
              style={{ marginBottom: 8 }}
            />
            <FormControlLabel
              data-cy="automatic-threshold-check-individual"
              value="individual"
              name="Apply to individual tests"
              control={<Radio />}
              label={
                <Box display="flex" alignItems="center">
                  <span style={{ marginRight: 8 }}>
                    Apply to individual tests (Enable below for each test)
                  </span>
                  <InfoTooltip
                    data-pendo="auto-tooltip-optimize-threhold-individual-tests"
                    data-testid="automatic-threshold-tooltip-individual"
                    title={
                      <>
                        <b>
                          Automatically optimize threshold on a per test basis
                        </b>
                        <p>
                          Choose which tests to automatically set thresholds.
                        </p>
                      </>
                    }
                  />
                </Box>
              }
              data-pendo="auto-smart-threshold-apply-to-individual-tests"
            />
          </RadioGroup>
          <Divider />
        </Box>
      </Collapse>
    </Box>
  );
}
