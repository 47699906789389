import React from "react";
import { TestSuiteReportTestResult } from "./TestSuiteReportTestResult";
import { makeStyles } from "@material-ui/core";
import {
  isTestToBeDisplayed,
  sortHealthScoreTestResults,
  sortTestResults,
  TestsToDisplayOption,
} from "./TestSuiteReportListUtils";
import { getCustomExtractionTestName } from "../../../_common/utils/getCustomExtractionTestName/getCustomExtractionTestName";
import { insertIf, useSession } from "@lumar/shared";
import { GetTestSuiteReportQuery, Severity } from "../../../graphql";
import { isReportTemplateCodeCustomExtraction } from "../../../_common/utils/isReportTemplateCodeCustomExtraction/isReportTemplateCodeCustomExtraction";
import { getCustomExtractionTooltip } from "../../../_common/utils/getCustomExtractionTooltip/getCustomExtractionTooltip";
import clsx from "clsx";

export type TestResultConfig = {
  type: string;
  title: string;
  totalCount: number;
  failedCount: number;
  warningCount: number;
  passedCount: number;
  component: React.ComponentType<{
    testSuiteReport: NonNullable<GetTestSuiteReportQuery["node"]>;
    testsToDisplay: TestsToDisplayOption;
  }>;
};

export function getTestResultConfigs(
  testSuiteReport: NonNullable<GetTestSuiteReportQuery["node"]>,
): TestResultConfig[] {
  return [
    ...insertIf(
      testSuiteReport.testResults.nodes.length,
      getReportTestResultConfig(testSuiteReport),
    ),
    ...insertIf(
      testSuiteReport.healthScoreTestResults.nodes.length,
      getHealthScoreTestResultConfig(testSuiteReport),
    ),
  ];
}

function getReportTestResultConfig(
  testSuiteReport: NonNullable<GetTestSuiteReportQuery["node"]>,
): TestResultConfig {
  const failedCount = testSuiteReport.failedTestCount || 0;
  const warningCount = testSuiteReport.warnedTestCount || 0;
  const passedCount = testSuiteReport.passedTestCount || 0;

  return {
    type: "report",
    title: "Report Test Results",
    totalCount: failedCount + warningCount + passedCount,
    failedCount,
    warningCount,
    passedCount,
    component: ReportTestResults,
  };
}

function ReportTestResults({
  testSuiteReport,
  testsToDisplay,
}: {
  testSuiteReport: NonNullable<GetTestSuiteReportQuery["node"]>;
  testsToDisplay: TestsToDisplayOption;
}): JSX.Element {
  const sortedTestsResults = sortTestResults(testSuiteReport.testResults.nodes);

  return (
    <>
      {sortedTestsResults.map((testResult) => {
        const testName = getCustomExtractionTestName(
          testResult.reportTemplate,
          testSuiteReport?.testSuite?.customExtractions,
        );
        const testSummary = isReportTemplateCodeCustomExtraction(
          testResult.reportTemplate.code,
        )
          ? getCustomExtractionTooltip(testName)
          : testResult.reportTemplate.automatorSummary;

        return isTestToBeDisplayed(testResult, testsToDisplay) ? (
          <TestSuiteReportTestResult
            key={testResult.reportTemplate.code}
            isPassed={!!testResult.passed}
            severity={testResult.severity}
            testName={testName}
            description={testSummary}
            linkLabel="Open report"
            link={`${testSuiteReport.crawlDcWebUrl}/reports/${testResult.reportTemplate.code}_basic`}
          />
        ) : null;
      })}
    </>
  );
}

function getHealthScoreTestResultConfig(
  testSuiteReport: NonNullable<GetTestSuiteReportQuery["node"]>,
): TestResultConfig {
  const failedCount = testSuiteReport.failedHealthScoreTestCount || 0;
  const warningCount = testSuiteReport.warnedHealthScoreTestCount || 0;
  const passedCount = testSuiteReport.passedHealthScoreTestCount || 0;

  return {
    type: "healthScore",
    title: "Health Score Test Results",
    totalCount: failedCount + warningCount + passedCount,
    failedCount,
    warningCount,
    passedCount,
    component: HealthScoreTestResults,
  };
}

function HealthScoreTestResults({
  testSuiteReport,
  testsToDisplay,
}: {
  testSuiteReport: NonNullable<GetTestSuiteReportQuery["node"]>;
  testsToDisplay: TestsToDisplayOption;
}): JSX.Element {
  const classes = useStyles();
  const session = useSession();

  const sortedHealthScoreTestsResults = sortHealthScoreTestResults(
    testSuiteReport.healthScoreTestResults.nodes,
  );

  return (
    <>
      {sortedHealthScoreTestsResults.map((testResult) => {
        return isTestToBeDisplayed(testResult, testsToDisplay) ? (
          <TestSuiteReportTestResult
            key={testResult.reportCategory.code}
            isPassed={!!testResult.passed}
            severity={testResult.severity}
            testName={
              <>
                {testResult.reportCategory.name}
                {testResult.healthScoreValue !== null &&
                  testResult.absoluteThreshold !== null && (
                    <span className={classes.healhScoreDetail}>
                      <span
                        className={clsx({
                          [classes.badge]: true,
                          [classes.badgeSuccess]: testResult.passed,
                          [classes.badgeFail]:
                            !testResult.passed &&
                            testResult.severity === Severity.Fail,
                          [classes.badgeWarning]:
                            !testResult.passed &&
                            testResult.severity === Severity.Warning,
                        })}
                      >
                        {getHealtScoreValue(testResult.healthScoreValue)}
                      </span>{" "}
                      / 100 vs threshold{" "}
                      <span className={classes.badge}>
                        {getHealtScoreValue(testResult.absoluteThreshold)}
                      </span>{" "}
                      / 100
                    </span>
                  )}
              </>
            }
            description={testResult.reportCategory.description}
            linkLabel="Open overview"
            link={
              session.hasAddon("health-scores")
                ? `${testSuiteReport.crawlDcWebUrl}?category=${testResult.reportCategory.code}`
                : undefined
            }
          />
        ) : null;
      })}
    </>
  );
}

function getHealtScoreValue(value: number): number {
  return Math.floor(value * 100 + 0.0001);
}

const useStyles = makeStyles((theme) => ({
  healhScoreDetail: {
    fontWeight: 500,
    marginLeft: theme.spacing(1.375),
  },
  badge: {
    display: "inline-block",
    background: theme.palette.grey[100],
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(0.5, 1),
    fontWeight: 600,
  },
  badgeFail: {
    background: theme.palette.red[100],
    color: theme.palette.red[800],
  },
  badgeWarning: {
    background: theme.palette.orange[100],
    color: theme.palette.orange[800],
  },
  badgeSuccess: {
    background: theme.palette.green[100],
    color: theme.palette.green[800],
  },
}));
