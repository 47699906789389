import { ApolloError } from "@lumar/shared";
import {
  ModuleCode,
  useGetReportCategoriesQuery,
  useGetReportTemplatesQuery,
} from "../../../../../graphql";
import { ReportCategory } from "../ChooseHealthScoreTestsForm/ChooseHealthScoreTestsFormUtils";
import { useMemo } from "react";

export function useFilteredReportCategories(moduleCode: ModuleCode): {
  data: ReportCategory[];
  loading?: boolean;
  error?: ApolloError;
} {
  const {
    loading: loadingTemplates,
    data: reportTemplates,
    error: reportTemplatesError,
  } = useGetReportTemplatesQuery({
    variables: {
      filter: { primaryReportCategoryCode: { ne: "removed" } },
    },
  });

  const {
    loading: loadingCategories,
    data: reportCategories,
    error: reportCategoriesError,
  } = useGetReportCategoriesQuery({
    variables: {
      filter: { healthScore: { eq: true } },
    },
  });

  const filteredReportTemplates =
    reportTemplates?.getAutomatorReportTemplates.edges.filter((e) =>
      e.node.supportedModules.find((m) => m === moduleCode),
    );

  const categories = useMemo(
    () =>
      reportCategories?.getReportCategories?.edges
        .filter((c) =>
          filteredReportTemplates?.find((r) => r.node.category === c.node.code),
        )
        .map<ReportCategory>((c) => c.node) ?? [],
    [filteredReportTemplates, reportCategories?.getReportCategories?.edges],
  );

  return {
    data: categories,
    loading: loadingTemplates || loadingCategories,
    error: reportCategoriesError ?? reportTemplatesError,
  };
}
