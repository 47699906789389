import { BuildTestListItemProps } from "../components/BuildTestListItem";
import { getBuildTestProcessedValue } from "../../../_common/utils/getBuildTestProcessedValue/getBuildTestProcessedValue";
import { getBuildTestStatus } from "../../../_common/utils/getBuildTestStatus/getBuildTestStatus";
import { differenceToHumanReadableFormatFromStrings } from "../../../_common/utils/date/date";
import { Build, BuildTriggerType, Maybe } from "../../../graphql";

function getCiBuildId(ciBuildId: Maybe<string> | undefined): string {
  return ciBuildId || "Not provided";
}

export enum SchedulerTextOptions {
  SCHEDULED = "Scheduled",
  API = "API triggered",
  MANUAL = "Manually triggered",
}

function getBuildTriggerType(trigger: BuildTriggerType | null) {
  switch (trigger) {
    case BuildTriggerType.Ci:
      return SchedulerTextOptions.API;
    case BuildTriggerType.Schedule:
      return SchedulerTextOptions.SCHEDULED;
    case BuildTriggerType.Manual:
      return SchedulerTextOptions.MANUAL;
    default:
      return SchedulerTextOptions.API;
  }
}

export function buildTestItemInfoFactory(
  build: Pick<
    Build,
    | "status"
    | "passed"
    | "warnedTestCount"
    | "failedTestCount"
    | "passedTestCount"
    | "warnedHealthScoreTestCount"
    | "failedHealthScoreTestCount"
    | "passedHealthScoreTestCount"
    | "finishedAt"
    | "createdAt"
    | "ciBuildId"
    | "id"
    | "updatedAt"
    | "triggerType"
  >,
  testSuiteId: string,
): BuildTestListItemProps {
  const status = getBuildTestStatus(build);
  const duration = differenceToHumanReadableFormatFromStrings(
    build.createdAt as string,
    build.finishedAt as string,
  );
  const buildId = getCiBuildId(build.ciBuildId);

  const failedTests = build.failedTestCount || 0;
  const warningTests = build.warnedTestCount || 0;
  const passedTests = build.passedTestCount || 0;

  const failedHealthScoreTests = build.failedHealthScoreTestCount || 0;
  const warningHealthScoreTests = build.warnedHealthScoreTestCount || 0;
  const passedHealthScoreTests = build.passedHealthScoreTestCount || 0;

  return {
    id: build.id,
    testSuiteId,
    buildId,
    status,
    duration: getBuildTestProcessedValue(duration, status),
    lastRun: getBuildTestProcessedValue(build.finishedAt, status),
    failedTests: getBuildTestProcessedValue(failedTests, status),
    warningTests: getBuildTestProcessedValue(warningTests, status),
    passedTests: getBuildTestProcessedValue(passedTests, status),
    failedHealthScoreTests: getBuildTestProcessedValue(
      failedHealthScoreTests,
      status,
    ),
    passedHealthScoreTests: getBuildTestProcessedValue(
      passedHealthScoreTests,
      status,
    ),
    warningHealthScoreTests: getBuildTestProcessedValue(
      warningHealthScoreTests,
      status,
    ),
    triggerType: getBuildTriggerType(build.triggerType),
    updatedAt: build.updatedAt,
  };
}
