import React from "react";
import { Threshold } from "../TestCollapse/TestCollapse";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import {
  StatusIcon,
  StatusIconSize,
  StatusIconType,
} from "../StatusIcon/StatusIcon";
import {
  ReportTemplateUnit,
  Severity,
  ThresholdPredicate,
  ThresholdType,
} from "../../../graphql";

const useStyles = makeStyles((theme) => ({
  error: {
    color: theme.palette.error.main,
    "& svg": {
      color: theme.palette.error.main,
      width: "14px",
      height: "14px",
    },
  },
  text: {
    display: "contents",
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(15),
    fontWeight: 600,
    color: theme.palette.grey[700],
  },
  severityBox: {
    background: theme.palette.red[50],
    lineHeight: theme.typography.pxToRem(15),
    padding: "3px 10px 3px 8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 6,
  },
  marginLeft: {
    marginLeft: 7,
  },
}));

export interface TestCollapseDescriptionProps {
  absoluteThresholdUpLimit: number;
  severity: Severity;
  threshold: Threshold;
  isExpanded: boolean;
  unit: ReportTemplateUnit | "/ 100";
}

export function TestCollapseDescription(
  props: TestCollapseDescriptionProps,
): JSX.Element {
  const classes = useStyles();

  function getStatusIconType(severity: Severity): StatusIconType {
    switch (severity) {
      case Severity.Fail:
        return StatusIconType.ERROR;
      case Severity.Warning:
        return StatusIconType.WARNING;
    }
  }

  function getSeverityText(severity: Severity): string {
    return severity === "Fail" ? "Fail" : "Warn";
  }

  function getThresholdText(
    threshold: Threshold,
    unit: ReportTemplateUnit | "/ 100",
  ): string {
    return threshold.type === ThresholdType.Relative
      ? `${threshold.relative || 0}%`
      : `${threshold.absolute || 0} ${unit}`;
  }

  if (
    (isNaN(props.threshold.absolute) || props.threshold.absolute < 1) &&
    !props.isExpanded
  ) {
    return (
      <span className={classes.error}>
        <ErrorOutlineIcon /> Please enter number of {props.unit}
      </span>
    );
  } else if (
    props.threshold.absolute > props.absoluteThresholdUpLimit &&
    !props.isExpanded
  ) {
    return (
      <span className={classes.error}>
        <ErrorOutlineIcon /> The number of {props.unit} is too large
      </span>
    );
  } else {
    return (
      <div className={classes.severityBox}>
        <span
          className={clsx(classes.text)}
          data-testid="test-collapse-overview-text"
        >
          {`${getSeverityText(props.severity)} if ${
            props.threshold.predicate === ThresholdPredicate.GreaterThanOrEqual
              ? "≥"
              : "<"
          } ${getThresholdText(props.threshold, props.unit)}`}
        </span>
        <div className={classes.marginLeft}>
          <StatusIcon
            type={getStatusIconType(props.severity)}
            size={StatusIconSize.XS}
          />
        </div>
      </div>
    );
  }
}
