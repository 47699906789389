import React from "react";
import { Box } from "@material-ui/core";
import {
  CustomChip,
  CustomChipType,
  getCorrectPathType,
} from "../../../pages/TestSuiteList/test-suite-list/TestCountChips";
import { Routes } from "../../routes/routes";
import { TestSuiteListItemProps } from "../../../pages/TestSuiteList/test-suite-list/TestSuiteListItem";
import { useAccountRouteMatch } from "../../hooks/useAccountRouteMatch/useAccountRouteMatch";
import { useHistory } from "react-router-dom";

type TestResultsProps = Pick<
  TestSuiteListItemProps,
  | "buildId"
  | "id"
  | "failedTests"
  | "warningTests"
  | "passedTests"
  | "failedHealthScoreTests"
  | "warningHealthScoreTests"
  | "passedHealthScoreTests"
>;

export function TestResults(props: TestResultsProps): JSX.Element {
  const accountId = useAccountRouteMatch();
  const history = useHistory();

  function handleClick(chipType: CustomChipType) {
    return (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.preventDefault();
      history.push(
        Routes.TestResults.getUrl({
          accountId,
          testSuiteId: props.id,
          buildTestId: props.buildId || "",
          selectedType: getCorrectPathType(chipType),
          type: getTestResultType(props, chipType),
        }),
      );
    };
  }

  const failedTests =
    getValidTestCount(props.failedTests) +
    getValidTestCount(props.failedHealthScoreTests);
  const warningTests =
    getValidTestCount(props.warningTests) +
    getValidTestCount(props.warningHealthScoreTests);
  const passedTests =
    getValidTestCount(props.passedTests) +
    getValidTestCount(props.passedHealthScoreTests);
  const totalTests = failedTests + warningTests + passedTests;

  return (
    <Box
      display="flex"
      alignItems="center"
      height="100%"
      data-testid="test-results"
    >
      <Box marginRight={0.5}>
        <CustomChip
          type={CustomChipType.Fails}
          label={failedTests}
          showLabel
          renderAsDisabled={failedTests === 0}
          pendoIdSuffix="testsuitelist"
          variant="default"
          onClick={
            failedTests > 0 ? handleClick(CustomChipType.Fails) : undefined
          }
        />
      </Box>
      <Box marginRight={0.5}>
        <CustomChip
          type={CustomChipType.Warnings}
          label={warningTests}
          showLabel
          renderAsDisabled={warningTests === 0}
          pendoIdSuffix="testsuitelist"
          variant="default"
          onClick={
            warningTests > 0 ? handleClick(CustomChipType.Warnings) : undefined
          }
        />
      </Box>
      <Box marginRight={0.5}>
        <CustomChip
          type={CustomChipType.Passes}
          label={passedTests}
          showLabel
          renderAsDisabled={passedTests === 0}
          pendoIdSuffix="testsuitelist"
          variant="default"
          onClick={
            passedTests > 0 ? handleClick(CustomChipType.Passes) : undefined
          }
        />
      </Box>
      <Box marginRight={0.5}>
        <CustomChip
          type={CustomChipType.Total}
          label={totalTests}
          renderAsDisabled={totalTests === 0}
          showLabel
          pendoIdSuffix="testsuitelist"
          variant="default"
          onClick={
            totalTests > 0 ? handleClick(CustomChipType.Total) : undefined
          }
        />
      </Box>
    </Box>
  );
}

function getTestResultType(
  props: TestResultsProps,
  type: CustomChipType,
): Parameters<typeof Routes.TestResults.getUrl>[0]["type"] {
  switch (type) {
    case CustomChipType.Fails:
      if (isValidTestCount(props.failedTests)) return "report";
      if (isValidTestCount(props.failedHealthScoreTests)) return "healthScore";
      return;
    case CustomChipType.Warnings:
      if (isValidTestCount(props.warningTests)) return "report";
      if (isValidTestCount(props.warningHealthScoreTests)) return "healthScore";
      return;
    case CustomChipType.Passes:
      if (isValidTestCount(props.passedTests)) return "report";
      if (isValidTestCount(props.passedHealthScoreTests)) return "healthScore";
      return;
  }
}

function isValidTestCount(value: string | number): boolean {
  return typeof value === "number" && Boolean(value);
}

function getValidTestCount(value: string | number): number {
  return typeof value === "number" ? value : 0;
}
