import { ModuleCode } from "../../../../../graphql";
import { TestCategory } from "../common/CreateTests.interfaces";

export const MAX_TESTS_SELECTION_LIMIT = 100;

export const RECOMMENDED_TESTS_CATEGORY_CODE = "recommended-tests";

export const recommendedTestsCategory: TestCategory = {
  selected: false,
  name: "Recommended Tests",
  code: RECOMMENDED_TESTS_CATEGORY_CODE,
  tests: [],
};

export const recomendedTestCodeList: Partial<Record<ModuleCode, string[]>> = {
  [ModuleCode.Accessibility]: ["accessibility"],
  [ModuleCode.Seo]: ["top"],
  [ModuleCode.SiteSpeed]: ["sitespeed"],
};
