import {
  GetTestSuiteReportTestsAndCustomExtractionsQuery,
  ModuleCode,
  ReportTemplateUnit,
} from "../../../../../graphql";

export const CUSTOM_EXTRACTION_CATEGORY = "custom-extractions";

export interface SuiteTestNodesExtended
  extends Omit<
    NonNullable<
      GetTestSuiteReportTestsAndCustomExtractionsQuery["node"]
    >["tests"]["nodes"][0],
    "reportTemplate" | "reportTemplateCode" | "__typename"
  > {
  base: {
    name: string;
    code: string;
    category: string;
    unit: ReportTemplateUnit | "/ 100";
    automatorSummary: string | null;
  };
}

export interface ExtendedTest {
  data: SuiteTestNodesExtended;
  extended: {
    selected: boolean;
    category: string;
    expanded: boolean;
    recomended: boolean;
    moduleCode: ModuleCode;
  };
}

export interface TestCategory {
  tests: ExtendedTest[];
  name: string;
  code: string;
  selected: boolean;
}

export interface ChooseTestsFilterOptions {
  showSelected: boolean;
  showAllCategories: boolean;
  textToSearchBy: string;
}
