import React from "react";
import { Typography, Divider } from "@material-ui/core";
import { useStyles } from "./ChooseTestsFormCountersStyles";
import { getSelectedTests } from "../common/CreateTestsCommonUtils";
import { ChooseTestsState } from "../ChooseTestsForm/chooseTestsReducer";

export interface ChooseTestsFormCountersProps {
  chooseTestsFormState: ChooseTestsState;
}

export function ChooseTestsFormCounters(props: ChooseTestsFormCountersProps) {
  const classes = useStyles();

  return (
    <div data-testid="tests-selected">
      <Divider className={classes.divider} />
      <Typography
        color="textPrimary"
        className={classes.selectedCount}
        data-testid="tests-selected-count"
      >
        Tests selected (
        {getSelectedTests(props.chooseTestsFormState.categories).length})
      </Typography>
      <Divider className={classes.divider} />
    </div>
  );
}
