import { BuildInfo } from "../Dashboard";
import { BuildStatus } from "../../../graphql";
import {
  BuildTestStatus,
  ExtendedBuildStatus,
} from "../../../_common/interfaces/BuildTestStatus";
import { getPassWarnOrFail } from "./getPassWarnOrFail";

export function getFinishedStatusFromBuild(build: BuildInfo): BuildTestStatus {
  if (build.status && build.status !== BuildStatus.Finished) {
    return build.status;
  }
  if (build.status === BuildStatus.Finished) {
    return getPassWarnOrFail(
      build.passed,
      build.warnedTestCount,
      build.warnedHealthScoreTestCount,
    );
  }
  return ExtendedBuildStatus.NotYetRun;
}
