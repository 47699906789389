import { Threshold } from "../../../../../_common/components/TestCollapse/TestCollapse";
import { Severity } from "../../../../../graphql";
import { ExtendedTest } from "../common/CreateTests.interfaces";

export function getNewTestFromInput(
  test: ExtendedTest,
  input: {
    threshold: Threshold;
    severity: Severity;
    expanded: boolean;
  },
) {
  const testToUpdate: ExtendedTest = {
    data: {
      ...test.data,
      relativeThreshold: input.threshold.relative,
      absoluteThreshold: input.threshold.absolute,
      thresholdType: input.threshold.type || test.data.thresholdType,
      severity: input.severity || test.data.severity,
      thresholdPredicate:
        input.threshold.predicate || test.data.thresholdPredicate,
    },
    extended: {
      ...test.extended,
      expanded: input.expanded,
    },
  };

  return testToUpdate;
}

export function isThresholdAndSeverityDifferent(
  testA: ExtendedTest,
  testB: ExtendedTest,
) {
  return (
    testA.data.thresholdPredicate !== testB.data.thresholdPredicate ||
    testA.data.relativeThreshold !== testB.data.relativeThreshold ||
    testA.data.absoluteThreshold !== testB.data.absoluteThreshold ||
    testA.data.thresholdType !== testB.data.thresholdType ||
    testA.data.severity !== testB.data.severity ||
    testA.extended.expanded !== testB.extended.expanded
  );
}
