/* eslint-disable max-lines */
import { TestCategory, ExtendedTest } from "../common/CreateTests.interfaces";
import {
  recomendedTestCodeList,
  RECOMMENDED_TESTS_CATEGORY_CODE,
  recommendedTestsCategory,
} from "./CreateTestsCommonConst";
import { compareStrings } from "../../../../../_common/utils/string/comparisons/comparisons";
import {
  compareTestByName,
  getSelectedTests,
} from "../common/CreateTestsCommonUtils";
import {
  CreateHealthScoreTestsMutationVariables,
  GetReportCategoriesQuery,
  GetTestSuiteHealthScoreTestsQuery,
  ModuleCode,
  Severity,
  ThresholdPredicate,
  ThresholdType,
  UpdateHealthScoreTestsMutationVariables,
} from "../../../../../graphql";

export type ReportCategory = Omit<
  GetReportCategoriesQuery["getReportCategories"]["edges"][0]["node"],
  "__typename"
>;

function getSelectedTestsFromData(
  tests: NonNullable<
    GetTestSuiteHealthScoreTestsQuery["node"]
  >["healthScoreTests"],
  reportCategoryCode: string,
) {
  return tests.nodes?.find(
    (test) => test.reportCategory.code === reportCategoryCode,
  );
}

function getCategoryCode(reportCategory: ReportCategory) {
  if (reportCategory.parentCode?.length) {
    return reportCategory.parentCode;
  } else {
    return reportCategory.code;
  }
}

function isTestCodeInRecomendedList(
  code: string,
  moduleCode: ModuleCode,
): boolean {
  return recomendedTestCodeList[moduleCode]?.indexOf(code) !== -1;
}

function compareCategoryByName(catA: TestCategory, catB: TestCategory) {
  return compareStrings(catA.name, catB.name);
}

const thresholdList: Partial<
  Record<ModuleCode, { value: number; type: ThresholdType }>
> = {
  [ModuleCode.Accessibility]: { value: 50, type: ThresholdType.Absolute },
  [ModuleCode.Seo]: { value: 50, type: ThresholdType.Absolute },
  [ModuleCode.SiteSpeed]: { value: 50, type: ThresholdType.Absolute },
};

function getExtendTestFromTemplates(
  reportCategory: ReportCategory,
  template: Omit<
    NonNullable<
      GetTestSuiteHealthScoreTestsQuery["node"]
    >["healthScoreTests"]["nodes"][0]["reportCategory"],
    "__typename"
  >,
  testSuiteModuleCode: ModuleCode,
): ExtendedTest {
  return {
    data: {
      id: "",
      relativeThreshold: thresholdList[testSuiteModuleCode]?.value ?? 50,
      createJiraTicketOnFailure: false,
      jiraTicketCustomNote: null,
      absoluteThreshold: thresholdList[testSuiteModuleCode]?.value ?? 50,
      thresholdType:
        thresholdList[testSuiteModuleCode]?.type ?? ThresholdType.Absolute,
      severity: Severity.Fail,
      base: {
        category: getCategoryCode(reportCategory),
        code: template.code,
        name: template.name,
        automatorSummary: template.automatorSummary,
        unit: "/ 100",
      },
      createdAt: new Date(),
      updatedAt: new Date(),
      thresholdPredicate: ThresholdPredicate.LessThan,
      automaticThresholdEnabled: false,
    },
    extended: {
      selected: false,
      expanded: false,
      category: "",
      recomended: isTestCodeInRecomendedList(
        reportCategory.code,
        testSuiteModuleCode,
      ),
      moduleCode: testSuiteModuleCode,
    },
  };
}

function createCompleteTestList(
  reportCategory: ReportCategory,
  testSuite: NonNullable<GetTestSuiteHealthScoreTestsQuery["node"]>,
): ExtendedTest {
  const template: Omit<
    NonNullable<
      GetTestSuiteHealthScoreTestsQuery["node"]
    >["healthScoreTests"]["nodes"][0]["reportCategory"],
    "__typename"
  > = {
    ...reportCategory,
  };

  const testSuiteModuleCode = testSuite.moduleCode;

  const testInitState: ExtendedTest = getExtendTestFromTemplates(
    reportCategory,
    template,
    testSuiteModuleCode,
  );

  const selectedTest = getSelectedTestsFromData(
    testSuite.healthScoreTests,
    reportCategory.code,
  );
  const categoryCode = getCategoryCode(reportCategory);

  const testName = testInitState.data.base.name;

  const testSummary = testInitState.data.base.automatorSummary;

  if (selectedTest) {
    return {
      data: {
        ...testInitState.data,
        ...selectedTest,
        absoluteThreshold: Math.floor(
          selectedTest.absoluteThreshold * 100 + 0.00001,
        ),
        base: {
          ...testInitState.data.base,
          name: testName,
          automatorSummary: testSummary,
        },
      },
      extended: {
        ...testInitState.extended,
        selected: true,
        category: categoryCode,
        moduleCode: testSuiteModuleCode,
      },
    };
  }

  return {
    data: {
      ...testInitState.data,
      base: {
        code: reportCategory.code,
        name: testName,
        automatorSummary: testSummary,
        unit: "/ 100",
        category: reportCategory.parentCode ?? "other",
      },
    },
    extended: {
      ...testInitState.extended,
      category: categoryCode,
      moduleCode: testSuiteModuleCode,
    },
  };
}

function getCategoryIndex(categories: TestCategory[], test: ExtendedTest) {
  return categories.findIndex(
    (category) => category.code === test.extended.category,
  );
}

function getNewCategory(
  categoryCode: string,
  categoryName: string,
  testList: ExtendedTest,
) {
  const name = "Tests for " + categoryName;
  return {
    code: categoryCode,
    name: name,
    tests: [testList],
    selected: false,
  };
}

/* eslint-disable fp/no-mutating-methods, max-statements */
function addTestToCategory(
  categories: TestCategory[],
  indexOfCategory: number,
  test: ExtendedTest,
) {
  categories[indexOfCategory].tests.push(test);
}

function getCategoryName(reportCategory: ReportCategory) {
  return `${reportCategory.name} Score`;
}

function addToOrCreateNewCategoryWithTest(
  testsCategories: TestCategory[],
  reportCategory: ReportCategory,
  testSuite: NonNullable<GetTestSuiteHealthScoreTestsQuery["node"]>,
) {
  const test = createCompleteTestList(reportCategory, testSuite);
  const indexOfCategory = getCategoryIndex(testsCategories, test);
  if (indexOfCategory > -1) {
    addTestToCategory(testsCategories, indexOfCategory, test);
  } else {
    const categoryCode = getCategoryCode(reportCategory);
    const newCategory: TestCategory = getNewCategory(
      categoryCode,
      getCategoryName(reportCategory),
      test,
    );
    testsCategories.push(newCategory);
  }
}

function getNewTestCategories(
  allReportCategories: ReportCategory[],
  testSuite: NonNullable<GetTestSuiteHealthScoreTestsQuery["node"]>,
) {
  const testsCategories: TestCategory[] = [];
  allReportCategories.forEach((category) => {
    addToOrCreateNewCategoryWithTest(testsCategories, category, testSuite);
  });
  return testsCategories;
}

/* eslint-disable fp/no-mutating-methods, fp/no-mutation */
function sortCategoriesAndTheirTestsByName(testsCategories: TestCategory[]) {
  return testsCategories.sort(compareCategoryByName).map((cat) => {
    return {
      name: cat.name,
      code: cat.code,
      selected: cat.selected,
      tests: cat.tests.sort(compareTestByName),
    };
  });
}

/* eslint-enable fp/no-mutating-methods, fp/no-mutation */
export function getCategories(
  allReportTemplates: ReportCategory[],
  testSuite: NonNullable<GetTestSuiteHealthScoreTestsQuery["node"]>,
) {
  const testsCategories: TestCategory[] = sortCategoriesAndTheirTestsByName(
    getNewTestCategories(allReportTemplates, testSuite),
  );
  return [recommendedTestsCategory]
    .concat(testsCategories)
    .filter(
      (category) =>
        category.tests.length > 0 ||
        category.code === RECOMMENDED_TESTS_CATEGORY_CODE,
    );
}

export function isTestInValid(
  test: ExtendedTest,
  absoluteThresholdUpLimit: number,
) {
  return (
    isNaN(test.data.absoluteThreshold) ||
    test.data.absoluteThreshold > absoluteThresholdUpLimit ||
    test.data.absoluteThreshold < 1
  );
}

export function isAnySelectedTestInvalid(
  categories: TestCategory[],
  absoluteThresholdUpLimit: number,
) {
  return (
    getSelectedTests(categories).filter((test) =>
      isTestInValid(test, absoluteThresholdUpLimit),
    ).length > 0
  );
}

export function notUndefined<T>(x: T | undefined): x is T {
  return x !== undefined;
}

// eslint-disable-next-line max-params
export function getCreateHealthScoreTestsMutationVariables(
  tests: ExtendedTest[],
  testSuiteId: string,
): CreateHealthScoreTestsMutationVariables {
  return {
    rulesAndThresholds: tests.map((test) => ({
      testSuiteId,
      relativeThreshold: test.data.relativeThreshold,
      absoluteThreshold: test.data.absoluteThreshold / 100,
      thresholdType: test.data.thresholdType,
      reportCategoryCode: test.data.base.code,
      severity: test.data.severity,
      thresholdPredicate: test.data.thresholdPredicate,
      automaticThresholdEnabled: test.data.automaticThresholdEnabled,
      createJiraTicketOnFailure: test.data.createJiraTicketOnFailure,
      jiraTicketCustomNote: test.data.jiraTicketCustomNote,
    })),
  };
}

export function getUpdateHealthScoreTestsMutationVariables(
  tests: ExtendedTest[],
): UpdateHealthScoreTestsMutationVariables {
  return {
    rulesAndThresholds: tests.map((test) => ({
      healthScoreTestId: test.data.id,
      relativeThreshold: test.data.relativeThreshold,
      absoluteThreshold: test.data.absoluteThreshold / 100,
      thresholdType: test.data.thresholdType,
      severity: test.data.severity,
      thresholdPredicate: test.data.thresholdPredicate,
      automaticThresholdEnabled: test.data.automaticThresholdEnabled,
      createJiraTicketOnFailure: test.data.createJiraTicketOnFailure,
      jiraTicketCustomNote: test.data.jiraTicketCustomNote,
    })),
  };
}
