import React, { useState } from "react";
import { StepChangeHandler } from "./UpdateTestSuite";
import { CustomStep } from "./CustomStep";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { PageContainer } from "../../_common/components/PageContainer/PageContainer";
import { CreateTestSuite } from "./components/ConnectSiteForm/CreateTestSuite";
import { SaveChangesProvider } from "./SaveChangesProvider";
import {
  AddonAvailability,
  insertIf,
  ModuleSelector,
  useSession,
} from "@lumar/shared";
import { ModuleCode } from "../../graphql";
import { useURLSearchParams } from "../../_common/routes/useURLSearchParams";
import { useSearchParam } from "../../_common/routes/useSearchParam";
//import { useURLSearchParams } from "../../_common/routes/useURLSearchParams";

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.grey[900],
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(23),
    lineHeight: theme.typography.pxToRem(28),
    margin: theme.spacing(0),
  },
  header: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0, 2.5, 0, 2.5),
    },
  },
}));

export function SetupTestSuite(): JSX.Element {
  const [expanded, setExpanded] = useState("step1");
  const classes = useStyles();
  const session = useSession();
  const searchParams = useURLSearchParams();

  const hasHealthScore =
    session.hasAddon("health-scores") &&
    session.hasFeatureFlagEnabled("protect-health-score-alerts");

  const module = useSearchParam("module") as ModuleCode;

  function handleChange(
    step: string,
    shouldExpandNextStep?: boolean,
  ): StepChangeHandler {
    return function (
      _?: React.ChangeEvent<{ value?: unknown }>,
      isExpanded?: boolean,
    ): void {
      setExpanded(shouldExpandNextStep || isExpanded ? step : "");
    };
  }

  const availableModules = [
    ...insertIf(session.hasAddon("seo"), {
      code: ModuleCode.Seo,
      title: "SEO",
      description:
        "Generate SEO reports and metrics to optimize for search engines.",
      actionLabel: "Select SEO",
      // TODO: Uncomment as soon as org cleans up accounts and starts giving the addon for free.
      // detail:
      //   session.getAddonAvailability("seo") === AddonAvailability.Free
      //     ? `🎊 We've given access to SEO feature to your account at no cost!`
      //     : undefined,
    }),
    ...insertIf(session.hasAddon("accessibility"), {
      code: ModuleCode.Accessibility,
      title: "Accessibility",
      description:
        "Generate Accessibility reports and metrics to optimize for inclusive user experiences and compliance with accessibility standards.",
      actionLabel: "Select Accessibility",
      // TODO: Uncomment as soon as org cleans up accounts and starts giving the addon for free.
      // detail:
      //   session.getAddonAvailability("accessibility") === AddonAvailability.Free
      //     ? `🎊 We've given access to accessibility feature to your account at no cost!`
      //     : undefined,
    }),
    ...insertIf(session.hasAddon("site-speed"), {
      code: ModuleCode.SiteSpeed,
      title: "Site Speed",
      description:
        "Generate Site Speed reports and metrics to optimize for speed performance.",
      actionLabel: "Select Site Speed",
      detail:
        session.getAddonAvailability("site-speed") === AddonAvailability.Free
          ? `🎉 New feature! We've given access to site speed feature to your account at no cost!`
          : undefined,
    }),
  ];

  const moduleCode =
    availableModules.length === 1 ? availableModules[0].code : module;

  if (availableModules.length && !moduleCode) {
    return (
      <ModuleSelector
        modules={availableModules}
        title="Test suite Settings"
        description="Select your test suite type."
        onModuleSelected={(module) => {
          searchParams.set("module", module);
          searchParams.navigate();
        }}
      />
    );
  }

  return (
    <SaveChangesProvider>
      <PageContainer>
        <Box className={classes.header}>
          <Typography
            variant="h1"
            className={classes.title}
            data-testid="setup-test-suite-title"
          >
            Add Test Suite
          </Typography>
        </Box>

        <CustomStep
          isExpanded={expanded === "step1"}
          handleChange={handleChange("step1")}
          stepHeader="Connect site"
          stepNumber={1}
          id="step1"
          testId="step1-collapse-container"
          pendoId="auto-add-test-suite-step1"
          description="Configure the website that will be crawled during the test, along with any necessary authentication settings."
        >
          <CreateTestSuite moduleCode={moduleCode} />
        </CustomStep>
        <CustomStep
          isExpanded={expanded === "step2"}
          stepHeader="Choose crawl settings"
          stepNumber={2}
          id="step2"
          testId="step2-collapse-container"
          pendoId="auto-add-test-suite-step2"
        />
        <CustomStep
          isExpanded={expanded === "step3"}
          stepHeader="Choose Report-based tests"
          stepNumber={3}
          id="step3"
          testId="step3-collapse-container"
          pendoId="auto-add-test-suite-step3"
        />
        {hasHealthScore && (
          <CustomStep
            isExpanded={expanded === "step4"}
            stepHeader="Choose Health Score-based tests"
            stepNumber={4}
            id="step6"
            testId="step6-collapse-container"
            pendoId="auto-add-test-suite-step6"
          />
        )}
        <CustomStep
          isExpanded={expanded === `step${hasHealthScore ? 5 : 4}`}
          stepHeader="Set notifications"
          stepNumber={hasHealthScore ? 5 : 4}
          id={`step${hasHealthScore ? 5 : 4}`}
          testId="step4-collapse-container"
          pendoId="auto-add-test-suite-step4"
        />
        <CustomStep
          isExpanded={expanded === `step${hasHealthScore ? 6 : 5}`}
          stepHeader="Run test suite"
          stepNumber={hasHealthScore ? 6 : 5}
          id={`step${hasHealthScore ? 6 : 5}`}
          testId="step5-collapse-container"
          pendoId="auto-add-test-suite-step5"
        />
      </PageContainer>
    </SaveChangesProvider>
  );
}
