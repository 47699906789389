import React, { useState, useEffect, useRef, useReducer } from "react";
import {
  Grid,
  Typography,
  LinearProgress,
  CircularProgress,
  Backdrop,
  Button,
  useTheme,
} from "@material-ui/core";
import { areAnyTestsSelected } from "../common/CreateTestsCommonUtils";
import {
  CUSTOM_EXTRACTION_CATEGORY,
  ExtendedTest,
} from "../common/CreateTests.interfaces";
import { useStyles } from "./ChooseTestFormStyles";
import { CollapsableTestsList } from "./../components/CollapsableTestsList";
import { ChooseTestsSelectionList } from "./../components/ChooseTestsSelectionList";
import {
  getCategories,
  areCustomExtractionArraysDifferent,
  isAnySelectedTestInvalid,
  getCreateTestsMutationVariables,
  getUpdateTestsMutationVariables,
} from "./ChooseTestsFormUtils";
import { SearchField } from "../../../../../_common/components/SearchField/SearchField";
import { ChooseTestsFilter } from "./../components/filter/ChooseTestFilter";
import { chunk } from "lodash";
import {
  PopoverDialog,
  PopoverDialogButton,
  PopoverDialogDefaultIdentifiers,
} from "../../../../../_common/components/PopoverDialog/PopoverDialog";
import { MAX_TESTS_SELECTION_LIMIT } from "./CreateTestsCommonConst";
import {
  chooseTestsReducer,
  initialChooseTestsFormValue,
} from "./chooseTestsReducer";
import { ChooseTestsDispatch } from "../../../../../_common/contexts/Permissions/ChooseTestsDispatch/ChooseTestsDispatch";
import { AutomaticThresholdSettings } from "./AutomaticThresholdSettings";
import InfoRoundedIcon from "@material-ui/icons/InfoRounded";
import {
  isResolutionWithin,
  ResolutionStep,
} from "../../../../../_common/utils/window/window";
import { StickyWrapper } from "../../StickyWrapper/StickyWrapper";
import { useSaveChangesContext } from "../../../SaveChangesProvider";
import { Alert } from "@lumar/shared";
import {
  GetTestSuiteQuery,
  GetTestSuiteReportTestsAndCustomExtractionsQuery,
  useCreateTestsMutation,
  useDeleteTestsMutation,
  useGetReportTemplatesQuery,
  useGetTestSuiteReportTestsAndCustomExtractionsQuery,
  useUpdateAutomaticThresholdMutation,
  useUpdateTestsMutation,
} from "../../../../../graphql";
import { getTotalShowingTestsCount } from "../components/ChooseTestsFormCountersUtils";

export interface ChooseTestsFormProps {
  absoluteThresholdUpLimit: number;
  testSuite: NonNullable<GetTestSuiteQuery["node"]>;
  project: NonNullable<GetTestSuiteQuery["getProject"]>;
  onSuccess: () => void;
  useTestSuiteTestsAndExtractionsQueryObject: ReturnType<
    typeof useGetTestSuiteReportTestsAndCustomExtractionsQuery
  >;
  hasChildren?: boolean;
  reportTemplateObject: ReturnType<typeof useGetReportTemplatesQuery>;
  smartThresholdSettings: {
    isEnabled: boolean;
    enabledForAllTests: boolean;
  };
  setSmartThresholdSettings: React.Dispatch<
    React.SetStateAction<{
      isEnabled: boolean;
      enabledForAllTests: boolean;
    }>
  >;
}

const popoverButtons: PopoverDialogButton[] = [
  {
    label: "Ok",
    identifier: PopoverDialogDefaultIdentifiers.OK,
    color: "secondary",
  },
];

// eslint-disable-next-line max-lines-per-function, max-statements, complexity
export function ChooseTestsForm(props: ChooseTestsFormProps): JSX.Element {
  const [chooseTestsFormState, chooseTestsFormDispatch] = useReducer(
    chooseTestsReducer,
    initialChooseTestsFormValue,
  );

  const popupReference = useRef(null);
  const classes = useStyles();
  const theme = useTheme();

  const {
    refetch: refetchTestsAndCustomExtractions,
    data: testsAndExtractions,
    error: testsAndExtractionsError,
    loading: testsAndExtractionsLoading,
  } = props.useTestSuiteTestsAndExtractionsQueryObject;

  const [customExtractions, setCustomExtractions] =
    useState<
      NonNullable<
        NonNullable<
          GetTestSuiteReportTestsAndCustomExtractionsQuery["node"]
        >["customExtractions"]
      >
    >();

  const [submitError, setSubmitError] = useState<string | undefined>();
  const [submitting, setSubmitting] = useState<boolean>();
  const [shouldRefreshCategories, setShouldRefreshCategories] =
    useState<boolean>(true);
  const MAX_ALLOWED_GRAPH_QL_CHUNK_SIZE = 99;
  const anyTestsSelected = areAnyTestsSelected(chooseTestsFormState.categories);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const [createTestsCall] = useCreateTestsMutation();
  const [updateTestsCall] = useUpdateTestsMutation();
  const [deleteTestsCall] = useDeleteTestsMutation();
  const [updateAutomaticThreshold] = useUpdateAutomaticThresholdMutation();

  const saveChangesContext = useSaveChangesContext();

  const {
    loading: areReportTemplatesLoading,
    data: reportTemplates,
    error: loadingError,
  } = props.reportTemplateObject;

  // Original code worked this way.
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
  const testsAndExtractionsNode = testsAndExtractions?.node!;

  // eslint-disable-next-line complexity
  useEffect(() => {
    function categoriesLoadedOrTestSuiteChangedOrSetToReload() {
      return (
        chooseTestsFormState.categories.length === 0 ||
        areCustomExtractionArraysDifferent(
          customExtractions,
          testsAndExtractionsNode?.customExtractions ?? undefined,
        ) ||
        shouldRefreshCategories
      );
    }

    if (
      !areReportTemplatesLoading &&
      !testsAndExtractionsLoading &&
      categoriesLoadedOrTestSuiteChangedOrSetToReload() &&
      testsAndExtractions &&
      reportTemplates?.getAutomatorReportTemplates.edges
    ) {
      setShouldRefreshCategories(false);
      setCustomExtractions(
        testsAndExtractionsNode?.customExtractions ?? undefined,
      );

      const filteredSEOReportTemplates =
        reportTemplates?.getAutomatorReportTemplates.edges.filter((e) =>
          e.node.supportedModules.find((m) => m === props.testSuite.moduleCode),
        );

      const newCategories = getCategories(
        [...filteredSEOReportTemplates],
        testsAndExtractionsNode,
      );
      chooseTestsFormDispatch({
        type: "SET_CATEGORIES",
        payload: { categories: newCategories },
      });
    }
  }, [
    areReportTemplatesLoading,
    chooseTestsFormState.categories.length,
    customExtractions,
    reportTemplates?.getAutomatorReportTemplates.edges,
    shouldRefreshCategories,
    testsAndExtractions,
    testsAndExtractionsLoading,
    testsAndExtractionsNode,
    props.testSuite.moduleCode,
  ]);

  useEffect(() => {
    setShouldRefreshCategories(true);
  }, [testsAndExtractions]);

  function createTestsFn(tests: ExtendedTest[]) {
    const testList = tests.filter(
      (test) => !test.data.id && test.extended.selected,
    );
    if (testList.length) {
      const variables = getCreateTestsMutationVariables(
        testList,
        props.testSuite.id,
      );
      return createTestsCall({
        variables,
      });
    }
  }

  useEffect(() => {
    async function deleteTestsData(
      tests: NonNullable<
        GetTestSuiteReportTestsAndCustomExtractionsQuery["node"]
      >["tests"]["nodes"],
    ): Promise<void> {
      if (tests.length)
        await deleteTestsCall({
          variables: { testIds: tests.map((x) => x.id) },
        });
    }

    if (testsAndExtractionsNode) {
      const testsToRun = testsAndExtractionsNode.tests?.nodes?.filter((x) =>
        x.reportTemplateCode.includes("custom_extraction"),
      );
      const customExtractionTests = testsAndExtractionsNode.customExtractions;

      const deleteList = testsToRun?.filter(
        (x) =>
          !customExtractionTests?.find(
            (y) => y.reportTemplateCode === x.reportTemplateCode,
          ),
      );
      if (deleteList.length) deleteTestsData(deleteList);
    }
  }, [testsAndExtractionsNode, deleteTestsCall]);

  function updateTestsFn(tests: ExtendedTest[]) {
    const testList = tests.filter(
      (test) => test.data.id && test.extended.selected,
    );
    if (testList.length) {
      const variables = getUpdateTestsMutationVariables(testList);
      return updateTestsCall({
        variables,
      });
    }
  }

  function deleteTestsFn(tests: ExtendedTest[]) {
    const testList = tests.filter(
      (test) => test.data.id && !test.extended.selected,
    );
    if (testList.length) {
      return deleteTestsCall({
        variables: {
          testIds: testList.map((e) => e.data.id),
        },
      });
    }
  }

  async function updateAutomaticThresholdOnTestSuite() {
    try {
      setSubmitting(true);
      await updateAutomaticThreshold({
        variables: {
          testSuiteId: props.testSuite.id,
          automaticThreshold: props.smartThresholdSettings.isEnabled,
          automaticThresholdEnabledForAll:
            props.smartThresholdSettings.enabledForAllTests,
        },
        refetchQueries: ["getTestSuite"],
      });
      setSubmitting(false);
    } catch {
      setSubmitting(false);
      setSubmitError(
        "There has been an error while saving data. Please refresh the page and try again.",
      );
    }
  }

  async function resolveTestsChunks(tests: ExtendedTest[][]): Promise<void> {
    try {
      const flatList = tests.reduce<ExtendedTest[]>((r, t) => [...r, ...t], []);
      await createTestsFn(flatList);
      await updateTestsFn(flatList);
      await deleteTestsFn(flatList);
      await updateAutomaticThresholdOnTestSuite();
      setSubmitError(undefined);
      refetchTestsAndCustomExtractions();
      setSubmitting(false);
    } catch (e) {
      refetchTestsAndCustomExtractions();
      setSubmitting(false);
      throw e;
    }
  }

  async function handleSave() {
    if (testsAndExtractionsLoading) return;
    if (!anyTestsSelected) {
      setSubmitError("Please add tests in order to continue.");
      setSubmitting(false);
      throw new Error("Please add tests in order to continue.");
    }
    const testChunks = chunk(
      chooseTestsFormState.categories.flatMap((category) => category.tests),
      MAX_ALLOWED_GRAPH_QL_CHUNK_SIZE,
    );

    if (testChunks.length) {
      setSubmitting(true);
      return resolveTestsChunks(testChunks);
    }
  }

  async function handleSubmit() {
    if (!areAnyTestsSelected(chooseTestsFormState.categories)) {
      return;
    }

    if (
      isAnySelectedTestInvalid(
        chooseTestsFormState.categories,
        props.absoluteThresholdUpLimit,
      )
    ) {
      setSubmitError(
        "Please fill in all required fields in order to continue.",
      );
      return;
    }

    try {
      await handleSave();
      props.onSuccess();
    } catch {
      setSubmitError(
        "There has been an error while saving data. Please refresh the page and try again.",
      );
    }
  }

  useEffect(() => {
    if (!props.testSuite.parent) {
      saveChangesContext.registerCallback(handleSave, "step3");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chooseTestsFormState]);

  function clearSubmitError() {
    setSubmitError(undefined);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function openMaxTestSelectionPopup() {
    setAnchorEl(popupReference.current);
  }

  const customExtractionSelectedTests = chooseTestsFormState.categories.filter(
    (cat) => cat.code === CUSTOM_EXTRACTION_CATEGORY,
  );
  const nonCustomExtractionSelectedTests =
    chooseTestsFormState.categories.filter(
      (cat) => cat.code !== CUSTOM_EXTRACTION_CATEGORY,
    );

  const filtersContainer = useRef<HTMLDivElement>(null);
  const testsContainer = useRef<HTMLDivElement>(null);
  const [computedMaxHeight, setComputedMaxHeight] = useState<number>(640);

  function calcHeight() {
    const areLocatedNextToEachOther = isResolutionWithin(ResolutionStep.SM);
    if (areLocatedNextToEachOther) {
      const filtersContainerBoundingBox =
        filtersContainer.current?.getBoundingClientRect();
      const testsConteinerBoundingBox =
        testsContainer.current?.getBoundingClientRect();
      if (testsConteinerBoundingBox && filtersContainerBoundingBox) {
        const max = Math.max(
          testsConteinerBoundingBox?.height -
            filtersContainerBoundingBox?.height,
          640,
        );
        setComputedMaxHeight(max);
      }
    } else {
      setComputedMaxHeight(640);
    }
  }
  useEffect(() => {
    window.addEventListener("resize", calcHeight);
    setTimeout(() => {
      calcHeight();
    }, 1000);

    return () => window.removeEventListener("resize", calcHeight);
  }, [chooseTestsFormState.categories]);

  if (areReportTemplatesLoading || testsAndExtractionsLoading) {
    return <CircularProgress data-testid="choose-tests-form-loading" />;
  }

  /* eslint-disable no-nested-ternary */
  return (
    <ChooseTestsDispatch.Provider value={chooseTestsFormDispatch}>
      <Grid container ref={popupReference}>
        <PopoverDialog
          anchorElement={anchorEl}
          handleAction={handleClose}
          open={Boolean(anchorEl)}
          text={`The maximum number of tests that you can select is ${MAX_TESTS_SELECTION_LIMIT}. Please deselect existing tests before choosing more.`}
          title="Can’t select more tests"
          buttons={popoverButtons}
          id={`{simple-popover-warning-${MAX_TESTS_SELECTION_LIMIT}}`}
          centered={true}
          icon={<InfoRoundedIcon className={classes.icon} />}
        />
        {loadingError || testsAndExtractionsError ? (
          <Alert severity="warning">
            There has been an error while loading data for this view.
          </Alert>
        ) : !areReportTemplatesLoading &&
          chooseTestsFormState.categories.length > 0 ? (
          <>
            <Grid item xs={12}>
              <div className={classes.padding}>
                <Grid container className={classes.containerStart} spacing={3}>
                  <Grid item sm={12} md={4}>
                    <div className={classes.filterAndControlsWrapper}>
                      <div ref={filtersContainer} id="layoutAffector">
                        <div
                          data-testid="tests-available"
                          style={{ marginBottom: theme.spacing(1) }}
                        >
                          <Typography
                            color="textPrimary"
                            data-cy="available-tests-count"
                            className={classes.totalTestsTitle}
                          >
                            Tests available (
                            {getTotalShowingTestsCount(
                              chooseTestsFormState.filter,
                              chooseTestsFormState.categories,
                            )}
                            )
                          </Typography>
                          <SearchField />
                        </div>
                        <ChooseTestsFilter
                          chooseTestsFormState={chooseTestsFormState}
                          filterToggle={() => {
                            setTimeout(() => {
                              calcHeight();
                            }, 1000);
                          }}
                        />
                      </div>
                      <ChooseTestsSelectionList
                        maxHeight={computedMaxHeight}
                        chooseTestsFormState={chooseTestsFormState}
                        clearSubmitError={clearSubmitError}
                        openMaxTestSelectionPopup={openMaxTestSelectionPopup}
                        renderingProgressed={calcHeight}
                      />
                    </div>
                  </Grid>
                  <Grid item sm={12} md={8} ref={testsContainer}>
                    {!props.hasChildren && !props.testSuite.parent && (
                      <AutomaticThresholdSettings
                        smartThresholdSettings={props.smartThresholdSettings}
                        handleSmartThresholdSettingsChange={
                          props.setSmartThresholdSettings
                        }
                      />
                    )}
                    {props.testSuite.customExtractions &&
                      props.testSuite.customExtractions.length > 0 && (
                        <>
                          <Typography
                            data-cy="custom-extractions-test-list-header"
                            variant="h5"
                            className={
                              classes.collapsableListHeadingCustomExtraction
                            }
                          >
                            Custom Extractions
                          </Typography>
                          <CollapsableTestsList
                            absoluteThresholdUpLimit={
                              props.absoluteThresholdUpLimit
                            }
                            isJiraIntegrationConnected={Boolean(
                              props.testSuite.testSuiteJiraIntegration,
                            )}
                            noItemsMessage="If you’ve added a custom extraction in step 2, please also select it from the list of tests on the left. You can search or filter for 'custom extractions'."
                            noItemsMessageId="no-custom-extractions-message"
                            categories={customExtractionSelectedTests}
                            clearSubmitError={clearSubmitError}
                            renderingProgressed={() => calcHeight()}
                            smartThresholdSettings={
                              props.smartThresholdSettings
                            }
                          />
                        </>
                      )}
                    <Typography
                      variant="h5"
                      className={classes.collapsableListHeadingOtherTests}
                    >
                      Tests selected
                    </Typography>
                    <CollapsableTestsList
                      absoluteThresholdUpLimit={props.absoluteThresholdUpLimit}
                      isJiraIntegrationConnected={Boolean(
                        props.testSuite.testSuiteJiraIntegration,
                      )}
                      categories={nonCustomExtractionSelectedTests}
                      clearSubmitError={clearSubmitError}
                      renderingProgressed={() => calcHeight()}
                      smartThresholdSettings={props.smartThresholdSettings}
                    />

                    {submitError && (
                      <Alert
                        data-cy="submit-error"
                        className={classes.submitError}
                        severity="error"
                      >
                        {submitError}
                      </Alert>
                    )}
                  </Grid>
                </Grid>
              </div>
              <Backdrop open={!!submitting} className={classes.backdrop}>
                <CircularProgress
                  data-testid="choose-tests-form-loading-indicator"
                  color="inherit"
                />
              </Backdrop>

              <StickyWrapper>
                <Button
                  disabled={!anyTestsSelected || submitting}
                  onClick={handleSubmit}
                  data-testid="choose-tests-form-save"
                  data-cy="choose-tests-form-save"
                  data-pendo="auto-testsuite-edit-add-choose-tests-save-step"
                  variant="contained"
                  color="primary"
                >
                  Save
                </Button>
              </StickyWrapper>
            </Grid>
          </>
        ) : (
          <div className={classes.progresssBar}>
            <LinearProgress key="linear-progress" />
          </div>
        )}
      </Grid>
    </ChooseTestsDispatch.Provider>
  );
  /* eslint-enable no-nested-ternary */
}
