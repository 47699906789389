import { ExtendedTest } from "../../../../pages/TestSuite/components/ChooseTests/common/CreateTests.interfaces";

export function numericSort(array: ExtendedTest[]): ExtendedTest[] {
  // Note: safe to do as we are spreading into a new array first
  // eslint-disable-next-line fp/no-mutating-methods
  const newArray = [...array].sort(
    (a, b) =>
      +a.data.base.name.localeCompare(b.data.base.name, undefined, {
        numeric: true,
      }),
  );
  return newArray;
}
